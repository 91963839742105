import * as PropTypes from "prop-types"
import React from "react"
import { IntlProvider, FormattedMessage } from "react-intl"
import messages from "../../../i18n/share_buttons/share_buttons_translation"

const FacebookShareButton = ({ url, locale }) => <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
    <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer.php?u=${encodeURI(url)}`} className="button social-button" style={{"display":"block", "background": "transparent"}}>
    <div className="row align-items-stretch">
      <div className="col-auto align-items-stretch" style={{display: "flex"}}>

        <svg width="24px" height="24px" viewBox="0 0 24 24">
          <defs>
            <polygon id="path-1" points="0.00140625 0.00094302554 24 0.00094302554 24 23.9948134 0.00140625 23.9948134"></polygon>
          </defs>
          <g id="Validated" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Blog-Article-Web" transform="translate(-356.000000, -2479.000000)">
              <g id="Group-2" transform="translate(120.000000, 2467.000000)">
                <g id="FB-button">
                  <g id="f_logo_RGB-White_1024" transform="translate(236.000000, 12.000000)">
                    <mask id="mask-2" fill="white">
                      <use xlinkHref="#path-1"></use>
                    </mask>
                    <g id="Clip-2"></g>
                    <path d="M24,12.0707269 C24,5.40424361 18.6274219,0 12,0 C5.37257813,0 0,5.40424361 0,12.0707269 C0,18.0955756 4.38822656,23.0892731 10.125,23.9948134 L10.125,15.5599214 L7.078125,15.5599214 L7.078125,12.0707269 L10.125,12.0707269 L10.125,9.41139489 C10.125,6.38616896 11.9165156,4.7151277 14.6575781,4.7151277 C15.9704766,4.7151277 17.34375,4.95088409 17.34375,4.95088409 L17.34375,7.92141454 L15.8305781,7.92141454 C14.3398828,7.92141454 13.875,8.85187426 13.875,9.80645187 L13.875,12.0707269 L17.203125,12.0707269 L16.6710938,15.5599214 L13.875,15.5599214 L13.875,23.9948134 C19.6117734,23.0892731 24,18.0955756 24,12.0707269" id="Fill-1" fill="#000000" mask="url(#mask-2)"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>

      </div>

      <div className="cropped-content">
        <div className="social-button-detail">
          <FormattedMessage id="share.facebook"/>
        </div>
      </div>


    </div>
  </a>
</IntlProvider>


FacebookShareButton.propTypes = { url: PropTypes.any }

export default FacebookShareButton