import React from "react"
import { graphql, Link } from "gatsby"
import MainLayout from "../components/layout/main"
import "../styles/main.scss"
import GatsbyImage from "gatsby-image"
import SEO from "../components/seo"
import BlogRichText from "../components/slices/blog/rich_text/blog_rich_text"
import BlogImage from "../components/slices/blog/image/blog_image"
import styles from "./blog_post.module.scss"
import moment from "moment"
import "moment/locale/fr"
import FacebookShareButton from "../components/blog/share/facebook_button"
import TwitterShareButton from "../components/blog/share/twitter_button"
import LinkedInShareButton from "../components/blog/share/linked_in_button"
import Button from "../components/button/button"
import BlogVideo from "../components/slices/blog/video/blog_video"
import LinkResolver from "../link_resolver"
import { IntlProvider, FormattedMessage } from "react-intl"
import messages from "../i18n/blog/blog_translation"
import { Helmet } from "react-helmet"
import { Disqus } from "gatsby-plugin-disqus"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/prism"

const BlogPost = ({ pageContext, data: { prismicBlogP: { data, type, uid, alternate_languages, lang }, allPrismicLanguageBanner: { nodes } } }) => {

  let localeCode = lang.substr(0, 2)
  let publishedDate = moment(data.published_at).locale(localeCode).format("dddd D MMMM YYYY")
  let microDataFormat = moment(data.published_at).locale(localeCode).format("YYYY-mm-dd")
  let languageBanners = (
    alternate_languages.length > 0
      ?
      alternate_languages.map((language) => {
        let node = nodes.find((node) => node.lang === language.lang)
        return <div className="slice" key={node.lang}>
          <div className={`container ${styles.languageBanner}`}>
            <div className="row">
              <p>{node.data.first_sentence.text}</p>
              <Link
                to={LinkResolver.resolveLink({ type: type, document: { uid: language.uid, language: language.lang } })}
                style={{ "color": "inherit" }}>
                <p>&nbsp;{node.data.name_to_link.text}&nbsp;</p>
              </Link>
              <p>{node.data.end_of_sentence.text}</p>
            </div>
          </div>
        </div>
      })
      :
      null
  )

  let disqusConfig = {
    url: "https://mobiten.com" + LinkResolver.resolveLink({ type: type, document: { uid: uid, language: lang}}),
    identifier: pageContext.disqus_id,
    title: data.title.text.substring(0, 200),
  }

  return <IntlProvider locale={localeCode} key={localeCode} messages={messages[localeCode]}>
    <Helmet>
      {
        alternate_languages.map((language) => {
          let node = nodes.find((node) => node.lang === language.lang)
          return <link rel="alternate" hrefLang={node.lang} href={LinkResolver.resolveLink({
            type: type,
            document: { uid: language.uid, language: language.lang },
          })}/>
        })
      }
    </Helmet>
    <MainLayout title={data.title.text} theme="light">
      <SEO description={data.description.text} title={data.title.text}
           poster={data.poster.localFile.childImageSharp.fixed.src} lang={localeCode}/>
      <div className="slice mt-spacing-24 mt-xl-spacing-48 mb-spacing-48 mb-xl-spacing-96">
        <div className="container">
        <span className="h-companion" itemType="http://schema.org/Person"><span
          itemProp="author">{data.author.document[0].data.name.text}</span>, <time
          dateTime={microDataFormat} itemProp="datePublished">{publishedDate}</time></span>
        </div>
      </div>

      {alternate_languages.length > 0 ? languageBanners : null}

      <div className="slice">
        <div className="container">
          <div className={styles.heroImage}>
            <GatsbyImage className="animated-image play-animation"
                         fluid={data.main_illustration.localFile.childImageSharp.fluid} alt={data.title.text}/>
            <div className="animated-image-overlay play-animation"/>
          </div>
        </div>
      </div>

      <div className="slice">
        <div className="container">
          <div className="row">
            <div className={`col-12 order-last order-xl-first col-xl-3 ${styles.blogSideBar}`}>
              <GatsbyImage fluid={data.author.document[0].data.picture.localFile.childImageSharp.fluid}
                           alt={data.author.document[0].data.name.text}
                           className={styles.avatar}
              />
              <div className={styles.blogSideBarSticky}>
                <div className={styles.blogSideBarContact}>
                  <h5 className="mt-spacing-48"><FormattedMessage id="contact.headline"/></h5>
                  <p className="small mt-spacing-8"><FormattedMessage id="contact.detail"
                                                                      values={{ auteur: `${data.author.document[0].data.name.text}` }}/>
                  </p>
                  <div className="mt-spacing-24">
                    <Button style={{ backgroundColor: "black" }} text={<FormattedMessage id="author.cta"
                                                                                         values={{ auteur: data.author.document[0].data.name.text.split(" ")[0] }}/>}
                            uid={uid} directLink={"#contact"} theme={"black"}/>
                  </div>
                </div>

                <p className={`small mt-spacing-32 offset-lg-2 pl-lg-spacing-8 ${styles.blogSideBarShareNote}`}>Partager
                  cette publication</p>
                <div className={`offset-lg-2 pl-lg-spacing-8 offset-xl-0 pl-xl-spacing-0 ${styles.blogSideBarShare}`}>
                  <div className="mt-spacing-4">
                    <FacebookShareButton url={`https://www.mobiten.com/blog/${uid}`} locale={localeCode}/>
                  </div>
                  <div className="mt-spacing-4">
                    <TwitterShareButton url={`https://www.mobiten.com/blog/${uid}`} title={data.title.text}
                                        locale={localeCode}/>
                  </div>
                  <div className="mt-spacing-4">
                    <LinkedInShareButton url={`https://www.mobiten.com/blog/${uid}`} title={data.title.text}
                                         locale={localeCode}/>
                  </div>
                </div>
              </div>
            </div>


            <article className={`col-12 offset-md-2 col-md-8 offset-xl-1 col-xl-7 ${styles.blogContent}`}>
              <h2 itemProp="description">{data.description.text}</h2>
              {
                data.body
                  .map(slice => {
                    let component

                    switch (slice["__typename"]) {
                      case "PrismicBlogPBodyTextSection":
                        component = <BlogRichText richText={slice.primary.rich_text}/>
                        break
                      case "PrismicBlogPBodyImage":
                        if (slice.primary.caption.text) {
                          component =
                            <figure className={"mx-spacing-n16"}>
                              <BlogImage image={slice.primary.image}/>
                              <figcaption
                                className="mt-spacing-12 mb-spacing-64 mx-spacing-16">{slice.primary.caption.text}</figcaption>
                            </figure>
                        } else {
                          component = <div className={"mx-spacing-n16"}><BlogImage image={slice.primary.image}/></div>
                        }
                        break
                      case "PrismicBlogPBodyVideo":
                        component = <BlogVideo caption={slice.primary.caption.text} video={slice.primary.video.url}
                                               aspectRatio={slice.primary.aspect_ratio}/>
                        break
                      case "PrismicBlogPBodyCodeSnippet":
                        component = <SyntaxHighlighter language={slice.primary.snippet_language} style={tomorrow}
                                                       className={styles.codeSnippet}>
                          {slice.primary.code_snippet.text}
                        </SyntaxHighlighter>
                    }
                    return <div className="my-spacing-24 my-md-spacing-48" key={slice.id}>
                      {component}
                    </div>
                  })
              }
            </article>
          </div>
        </div>
      </div>

      <div className={`slice ${styles.blogContact} mb-spacing-none`}>
        <div className="container">
          <div className="row">
            <div className="col-12 my-spacing-48 my-md-spacing-96 offset-md-2 col-md-8 offset-xl-4 col-xl-8 ">
              <Disqus config={disqusConfig}/>
            </div>
          </div>
        </div>
      </div>

    </MainLayout>
  </IntlProvider>
}

export default BlogPost

export const pageQuery = graphql`
    query BlogPostQuery ($id: String!) {
        allPrismicLanguageBanner {
            nodes {
                lang
                data {
                    end_of_sentence {
                        text
                    }
                    first_sentence {
                        text
                    }
                    name_to_link {
                        text
                    }
                }
            }
        }
        prismicBlogP(id: {eq: $id}) {
            uid
            type
            lang
            alternate_languages {
                uid
                lang
            }
            data {
                title {
                    text
                }
                description {
                    text
                }
                author {
                    document {
                        data {
                            name {
                                text
                            }
                            picture {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 268) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                published_at
                main_illustration {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1168, maxHeight: 770) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                poster: main_illustration {
                    localFile {
                        childImageSharp {
                            fixed(width: 1330, height: 997) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
                body {
                    __typename
                    ... on PrismicBlogPBodyImage{
                        id
                        primary {
                            image {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 1000) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }

                            caption {
                                text
                            }
                        }
                    }

                    ... on PrismicBlogPBodyTextSection {
                        id
                        primary {
                            rich_text {
                                html
                            }
                        }
                    }

                    ... on PrismicBlogPBodyVideo {
                        id
                        primary {
                            video {
                                url
                            }

                            caption {
                                text
                            }
                            aspect_ratio
                        }
                    }

                    ... on PrismicBlogPBodyCodeSnippet {
                        id
                        primary {
                            snippet_language
                            code_snippet {
                                text
                            }
                        }
                    }

                }
            }
        }
    }`
