import React from "react"
import PropType from "prop-types"
import ReactPlayer from "react-player"


const BlogVideo = ({ caption, video, aspectRatio }) => {

  let containerStyle = aspectRatio != null ? { "position": "relative", "paddingTop": aspectRatio + "%" } : {}
  let playerStyle = aspectRatio != null ? { "position": "absolute", "top": 0, "left": 0 } : {}
  
  let player = <div style={containerStyle}>
    <ReactPlayer url={video} loop controls width="100%" height="100%"
                 style={playerStyle}/>
  </div>

  let component

  if (caption) {
    component =
      <div className="container">
        <figure className={"mx-spacing-n16"}>
          {player}
          <figcaption className="mt-spacing-12 mb-spacing-64 mx-spacing-16">{caption}</figcaption>
        </figure>
      </div>
  } else {
    component =
      <div className="container">
        <div className={"mx-spacing-n16"}>
          {player}
        </div>
      </div>
  }
  return component
}

BlogVideo.propTypes = {
  caption: PropType.string,
  video: PropType.any.isRequired,
  aspectRatio: PropType.number,
}

export default BlogVideo
