import React from "react"
import PropType from "prop-types"
import EmbedContainer from 'react-oembed-container';

const BlogRichText = ({ richText }) => (
  <EmbedContainer markup={richText.html}>
      <div dangerouslySetInnerHTML={{ __html: richText.html }}/>
  </EmbedContainer>
)

BlogRichText.propTypes = {
  richText: PropType.any.isRequired,
}

export default BlogRichText