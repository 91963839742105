import * as PropTypes from "prop-types"
import React from "react"
import { IntlProvider, FormattedMessage } from "react-intl"
import messages from "../../../i18n/share_buttons/share_buttons_translation"

const LinkedInShareButton = ({ url, title, locale }) => {
  let shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURI(url)}&title=${encodeURI(title)}`
  return <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
    <a target="_blank" rel="noopener noreferrer" href={shareUrl} className="button social-button" style={{"display":"block", "background": "transparent"}}>
      <div className="row align-items-stretch">
        <div className="col-auto align-items-stretch" style={{display: "flex"}}>

          <svg width="22px" height="24px" viewBox="0 0 22 24">
            <g id="Validated" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Blog-Article-Web" transform="translate(-358.000000, -2607.000000)" fill="#000000" fillRule="nonzero">
                <g id="Group-2" transform="translate(120.000000, 2467.000000)">
                  <g id="Twitter-button-Copy" transform="translate(0.000000, 128.000000)">
                    <g id="professional-network-linkedin" transform="translate(238.000000, 12.000000)">
                      <rect id="Rectangle" x="0" y="8.18181818" width="5.5" height="15.2727273" rx="0.545454545"></rect>
                      <ellipse id="Oval" cx="2.728" cy="3.27272727" rx="2.728" ry="2.72727273"></ellipse>
                      <path d="M18.15,23.4545455 L21.45,23.4545455 C21.7537566,23.4545455 22,23.2103371 22,22.9090909 L22,13.7454545 C22,9.63272727 19.657,7.63636364 16.379,7.63636364 C15.0715112,7.58893945 13.8048044,8.09222729 12.892,9.02181818 C12.768524,9.16329495 12.5692983,9.21359491 12.3926396,9.14789538 C12.2159808,9.08219584 12.0992288,8.91438353 12.1,8.72727273 C12.1,8.4260265 11.8537566,8.18181818 11.55,8.18181818 L8.25,8.18181818 C7.94624339,8.18181818 7.7,8.4260265 7.7,8.72727273 L7.7,22.9090909 C7.7,23.2103371 7.94624339,23.4545455 8.25,23.4545455 L11.55,23.4545455 C11.8537566,23.4545455 12.1,23.2103371 12.1,22.9090909 L12.1,14.7272727 C12.1,13.2210416 13.3312169,12 14.85,12 C16.3687831,12 17.6,13.2210416 17.6,14.7272727 L17.6,22.9090909 C17.6,23.2103371 17.8462434,23.4545455 18.15,23.4545455 Z" id="Path"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>

        </div>
        <div className="cropped-content">
          <div className="social-button-detail">
            <FormattedMessage id="share.linkedin"/>
          </div>
        </div>
      </div>
    </a>
  </IntlProvider>
}


LinkedInShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default LinkedInShareButton