import React from "react";
import PropType from 'prop-types';
import GatsbyImage from "gatsby-image"

const BlogImage = ({image}) => (
  <React.Fragment>
    <div className="container">
    <div className="cropped-content">
        <GatsbyImage className={`animated-image`} fluid={image.localFile.childImageSharp.fluid} />
        <div className="animated-image-overlay"></div>
      </div>
    </div>
  </React.Fragment>
)

BlogImage.propTypes = {
  image: PropType.any.isRequired
}

export default BlogImage