import * as PropTypes from "prop-types"
import React from "react"
import { IntlProvider, FormattedMessage } from "react-intl"
import messages from "../../../i18n/share_buttons/share_buttons_translation"

const TwitterShareButton = ({ url, title, locale }) => {
  let shareUrl = `https://twitter.com/share?url=${encodeURI(url)}&text=${title}`

  return <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
    <a target="_blank" rel="noopener noreferrer" href={shareUrl} className="button social-button" style={{"display":"block", "background": "transparent"}}>
      <div className="row align-items-stretch">

        <div className="col-auto align-items-stretch" style={{height: "19px"}}>

          <svg width="24px" height="19px" viewBox="0 0 24 19">
            <g id="Validated" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Blog-Article-Web" transform="translate(-356.000000, -2545.000000)" fill="#000000" fillRule="nonzero">
                <g id="Group-2" transform="translate(120.000000, 2467.000000)">
                  <g id="Twitter-button" transform="translate(0.000000, 64.000000)">
                    <g id="Logo__x2014__FIXED" transform="translate(236.750000, 14.320000)">
                      <path d="M7.074,18.324 C15.561,18.324 20.205,11.286 20.205,5.193 C20.205,4.995 20.205,4.797 20.196,4.599 C21.096,3.951 21.879,3.132 22.5,2.205 C21.672,2.574 20.781,2.817 19.845,2.934 C20.799,2.367 21.528,1.458 21.879,0.378 C20.988,0.909 19.998,1.287 18.945,1.494 C18.099,0.594 16.902,0.036 15.579,0.036 C13.032,0.036 10.962,2.106 10.962,4.653 C10.962,5.013 11.007,5.364 11.079,5.706 C7.245,5.517 3.843,3.672 1.566,0.882 C1.17,1.566 0.945,2.358 0.945,3.204 C0.945,4.806 1.764,6.219 2.997,7.047 C2.241,7.02 1.53,6.813 0.909,6.471 C0.909,6.489 0.909,6.507 0.909,6.534 C0.909,8.766 2.502,10.638 4.608,11.061 C4.221,11.169 3.816,11.223 3.393,11.223 C3.096,11.223 2.808,11.196 2.529,11.142 C3.114,12.978 4.824,14.31 6.84,14.346 C5.256,15.588 3.267,16.326 1.107,16.326 C0.738,16.326 0.369,16.308 0.009,16.263 C2.043,17.559 4.473,18.324 7.074,18.324" id="Path"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>

        </div>

        <div className="cropped-content">
          <div className="col social-button-detail">
            <FormattedMessage id="share.twitter"/>
          </div>
        </div>
      </div>
    </a>
  </IntlProvider>
}


TwitterShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default TwitterShareButton